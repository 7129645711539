<mat-card appearance="outlined">
    <div class="deployment-name-card">
        <button mat-icon-button (click)="goToDeployments()" data-cy="back-button">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <h1>{{deployment?.name}}</h1>
        <div></div>
    </div>
</mat-card>
<div id="summary-cards-container">
    <mat-card id="k-cell-card" class="summary-card">
        <h2>K-Cell</h2>
        <div>{{deployment.kCellSerialNumber | uppercase}}</div>
        <button mat-raised-button color="primary" (click)="goToKCell()" data-cy="manage-k-cell-button">Manage K-Cell</button>
    </mat-card>

    <mat-card id="devices-card" class="summary-card">
        <h2>Devices</h2>
        <div i18n>{{deployment.devices.length}} {{deployment.devices.length === 1 ? "Device" : "Devices" }}</div>
        <button mat-raised-button color="primary" (click)="goToDevices()" data-cy="manage-devices-button">Manage Devices</button>
    </mat-card>

    <mat-card id="plugin-card" class="summary-card">
        <h2>Plugins</h2>
        <div i18n>{{deployment.plugins.length}} {{deployment.plugins.length === 1  ? "Plugin" : "Plugins"}}</div>
        <button mat-raised-button color="primary" (click)="goToDeploymentPluginConfig(deployment.name)" data-cy="manage-plugins-button">View Plugin Config</button>
    </mat-card>
</div>
