<div class="margin-wrapper">
    <!-- Loading spinner view -->
    <ng-container *ngIf="loading">
        <h1 mat-dialog-title i18n>Loading K-Cell Details</h1>
        <div mat-dialog-content class="column-layout-align-center">
            <div>
                <mat-spinner class="load-spinner" diameter="24"></mat-spinner>
            </div>
        </div>
    </ng-container>
    <div id="gateway-management-configuration-container" *ngIf="!loading">
        <div mat-dialog-content>
                <div class="header-container">
                    <h2 i18n>Requests</h2>
                </div>
                <mat-card >
                    <div class="krucial-space-between-title-value">
                        <div i18n><b>Statuses</b></div>
                        <div>
                            <button mat-stroked-button
                                    (click)="sendPacketRequest()"
                                    data-cy="send-status-request-button" i18n>Send <mat-icon>send</mat-icon></button>
                        </div>
                    </div>
                    <div class="info-text">
                        <div i18n>Send a request for Statuses to the K-Cell.</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="krucial-space-between-title-value">
                        <div i18n><b>Reboot</b></div>
                        <div>
                            <button mat-stroked-button
                                    (click)="sendRebootPacketRequest()"
                                    data-cy="send-reboot-request-button" i18n>Send <mat-icon>send</mat-icon></button>
                        </div>
                    </div>
                    <div class="info-text">
                        <div i18n>Send a request to Reboot to the K-Cell.</div>
                    </div>
                    <mat-divider></mat-divider>
                </mat-card>
        </div>
    </div>
</div>
