import {Component, Inject, OnInit} from '@angular/core';
import {finalize} from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import {
  KCellDto,
  KCellService
} from "@r3-iot/api-sigma";
import {SetTimingsDto} from "@r3-iot/api-sigma/lib/model/setTimingsDto";
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-timings-config-dialog-dialog',
    templateUrl: './timings-config-dialog.component.html',
    styleUrls: ['./timings-config-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatDialogModule, MatProgressSpinnerModule, ReactiveFormsModule, MatCardModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule]
})
export class TimingsConfigDialogComponent implements OnInit {
  timingsForm: UntypedFormGroup;
  loading: boolean = true;
  gateway: KCellDto;

  constructor( private matDialogRef: MatDialogRef<TimingsConfigDialogComponent>,
               private kCellService: KCellService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.kCellService.v1KcellSerialNumberGet(this.data.serialNumber).pipe(untilDestroyed(this), finalize(() => {
      this.loading = false;
    })).subscribe({
      next: (gateway: KCellDto) => {
        if (!gateway) {
          return;
        }
        this.gateway = gateway;
        this.timingsForm = new UntypedFormGroup({
          appDataUplinkPeriod: new UntypedFormControl(null, [Validators.required]),
          statusUpdatePeriod: new UntypedFormControl(null, [Validators.required]),
          housekeepingUpdatePeriod: new UntypedFormControl(null, [Validators.required]),
          maxMtPollingPeriod: new UntypedFormControl(null, [Validators.required]),
        });
      },
      error: (err: any) => {}
    })
  }

  save(): void {
    const payload: SetTimingsDto = {
      appdataUplinkPeriod: this.timingsForm.get('appDataUplinkPeriod').value,
      statusUpdatePeriod: this.timingsForm.get('statusUpdatePeriod').value,
      housekeepingUpdatePeriod: this.timingsForm.get('housekeepingUpdatePeriod').value,
      mtPollingPeriod: this.timingsForm.get('maxMtPollingPeriod').value,
    }
    this.timingsForm.markAsPristine()
    this.matDialogRef.close(payload);
  }

}
