<div *ngIf="loading">
    <mat-spinner class="krucial-loading-spinner" [style.display]="loading ? 'block' : 'none'"></mat-spinner>
</div>
<div id="deployment-device-details-container" *ngIf="!loading">
    <br>
    <h2 i18n>Details</h2>
    <mat-card appearance="outlined" class="deployment-device-details-card">
        <div class="krucial-space-between-title-value">
            <div i18n><b>DevEUI</b></div>
            <div data-cy="dev-eui">{{device.devEui | AddSpaces}}</div>
        </div>
        <mat-divider></mat-divider>
        <ng-container *ngIf="device.appKey">
            <div class="krucial-space-between-title-value">
                <div i18n><b>App Key</b></div>
                <div data-cy="app-key">{{device.appKey}}</div>
            </div>
            <mat-divider></mat-divider>
        </ng-container>
        <ng-container *ngIf="device.appSkey">
            <div class="krucial-space-between-title-value">
                <div i18n><b>App Skey</b></div>
                <div data-cy="app-skey">{{device.appSkey}}</div>
            </div>
            <mat-divider></mat-divider>
        </ng-container>
        <ng-container *ngIf="device.nwkSkey">
            <div class="krucial-space-between-title-value">
                <div i18n><b>Nwk Skey</b></div>
                <div data-cy="nwk-skey">{{device.nwkSkey}}</div>
            </div>
            <mat-divider></mat-divider>
        </ng-container>
        <ng-container *ngIf="device.name">
            <div class="krucial-space-between-title-value">
                <div i18n><b>Name</b></div>
                <div data-cy="name">{{device.name}}</div>
            </div>
            <mat-divider></mat-divider>
        </ng-container>
        <div class="krucial-space-between-title-value">
            <div i18n><b>Device Profile</b></div>
            <div id="device-profile-container" data-cy="device-profile">
                <span>{{device?.deviceProfileName ? device.deviceProfileName : '--'}}</span>
                <span class="density-5">
                    <button mat-icon-button color="primary" (click)="openEditDeviceProfileDialog($event)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </span>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="krucial-space-between-title-value">
            <div i18n><b>Dev Address</b></div>
            <div data-cy="dev-address">{{device?.devAddress ? device.devAddress : '--'}}</div>
        </div>
        <mat-divider></mat-divider>
    </mat-card>
</div>
