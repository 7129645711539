import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, take} from 'rxjs';
import {Router} from "@angular/router";
import {DeploymentDto, DeploymentService, DeviceDto, DeviceService} from "@r3-iot/api-sigma";
import {tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class DeploymentDeviceService implements OnDestroy {

    // Observable for the currently selected cell
    protected pDevice: BehaviorSubject<DeviceDto> = new BehaviorSubject(null as unknown as DeviceDto);
    public readonly device$: Observable<DeviceDto> = this.pDevice.asObservable();

    // Error observable used by child components to bubble the error up to the parent GatewayManagement component
    protected pOnLoadError: BehaviorSubject<any> = new BehaviorSubject(null);
    public readonly onLoadError$: Observable<any> = this.pOnLoadError.asObservable();


    constructor(public deviceApiService: DeviceService, public router: Router) {}

    getDevice(devEui: string): Observable<DeviceDto> {
        return this.deviceApiService.v1DeviceDevEuiGet(devEui)
            .pipe(
                take(1),
                tap((device: DeviceDto) => this.pDevice.next(device))
            );
    }

    refresh(): void {
        this.getDevice(this.pDevice.value.devEui).subscribe({
            next: (device: DeviceDto) => {}
        })
    }

    raiseOnLoadError(error): void {
        this.pOnLoadError.next(error);
    }

    ngOnDestroy() {
        this.pDevice.unsubscribe();
    }
}
