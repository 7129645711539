<div>
    <h1 mat-dialog-title i18n>Select Replacement Gateway</h1>

    <div mat-dialog-content>
        <form [formGroup]="form">
            <mat-form-field>
                <input type="text" data-cy="replacement-gateway-input" matInput formControlName="replacementGatewayInput"
                       [matAutocomplete]="auto" placeholder="Serial Number" i18n-placeholder>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let gateway of filteredGateways | async" [value]="gateway" [attr.data-cy]="'replacement-gateway-option-' + gateway.serialNumber">
                        {{gateway.serialNumber.toUpperCase()}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>

    <div mat-dialog-actions>
        <button mat-raised-button color="primary" *ngIf="!saving" (click)="confirm()" [disabled]="!form.controls.replacementGatewayInput.value"
                data-cy="select-gateway-modal-confirm-button" i18n>Confirm</button>
        <div class="spinner-container" *ngIf="saving">
            <mat-spinner diameter="24"></mat-spinner>
        </div>
    </div>
</div>
