<div class="add-button">
    <button id="addDeviceToDeploymentButton" mat-raised-button color="primary"
            data-cy="add-device-to-deployment-button" (click)="openAddDeviceToDeploymentDialog()">
        <mat-icon>add_circle_outline</mat-icon>
        <span i18n> Add Device to Deployment</span>
    </button>
</div>
<br>
<div class="krucial-table-container">
    <mat-form-field>
        <input data-cy="device-filter" matInput (keyup)="applyDeviceFilter($event)"
               placeholder="Filter Devices" i18n-placeholder>
    </mat-form-field>

    <mat-table #devicesTable [dataSource]="deviceDataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="DevEUI">
            <mat-header-cell *matHeaderCellDef i18n> DevEUI</mat-header-cell>
            <mat-cell *matCellDef="let device"> {{device.devEui | AddSpaces}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef i18n> Device Name</mat-header-cell>
            <mat-cell *matCellDef="let device">{{device.name}}</mat-cell>
        </ng-container>

        <!-- Operations -->
        <ng-container matColumnDef="Operations">
            <mat-header-cell *matHeaderCellDef i18n> Operations</mat-header-cell>
            <mat-cell *matCellDef="let device">
                <button mat-button color="primary" (click)="goToDeploymentDevice($event, device.devEui)"
                        [attr.data-cy]="'device-edit-' + device.devEui">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-button color="primary" (click)="openDeleteDeviceDialog($event, device.devEui)"
                        [attr.data-cy]="'device-delete-' + device.devEui">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedDeviceColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedDeviceColumns;"
                 (click)="goToDeploymentDevice($event, row.devEui)"></mat-row>
    </mat-table>
    <mat-paginator #devicePaginator [pageSize] = "10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
