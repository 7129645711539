import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import {Amplify} from 'aws-amplify';
import { AppComponent } from './app/app.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedModule } from './app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import {provideRouter, RouterModule, withComponentInputBinding} from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { DeploymentDeviceModule } from './app/components/deployments/deployment-management/devices/devices-view/deployment-device/deployment-device.module';
import { DeploymentManagementModule } from './app/components/deployments/deployment-management/deployment-management.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertBannerService, R3CommonModule, AlertBannerModule } from '@r3-iot/common';
import { Configuration as ApiConfiguration, ApiModule } from '@r3-iot/api-sigma';
import { HttpHeadersInterceptor } from './app/shared/interceptors/http-headers.interceptor';
import { HttpErrorInterceptor } from './app/shared/interceptors/http-error.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import {ROUTES} from "./app/routes";
if (environment.production) {
  enableProdMode();
}

if (environment.offlineMode) {
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolEndpoint: "http://localhost:9229/",
                userPoolClientId: environment.clientId,
                userPoolId: environment.userPoolId,
                loginWith: {
                    oauth: {
                        domain: "http://localhost:9229/",
                        scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.users.admin'],
                        redirectSignIn: ['http://localhost:4201/', 'http://localhost:4201/login', 'http://aquatics.local.krucial.cloud/login'],
                        redirectSignOut: ['http://localhost:4201/', 'http://aquatics.local.krucial.cloud/'],
                        responseType: 'code'
                    },
                    username: false,
                    email: true,
                    phone: false,
                }
            }
        }
    });
} else {
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolClientId: environment.clientId,
                userPoolId: environment.userPoolId,
                loginWith: {
                    oauth: {
                        domain: 'https://r3iot.auth.eu-west-2.amazoncognito.com',
                        scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.users.admin'],
                        redirectSignIn: ['http://localhost:4201/', 'http://localhost:4201/login'],
                        redirectSignOut: ['http://localhost:4201/'],
                        responseType: 'code'
                    },
                    username: false,
                    email: true,
                    phone: false,
                }
            }
        }
    });
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(ApiModule, AmplifyAuthenticatorModule, BrowserModule, DeploymentManagementModule.forRoot({
            RolesConfig: [
                'r3_developer',
                'r3_admin',
                'r3_support',
                'connect_admin'
            ]
        }), DeploymentDeviceModule.forRoot({
            RolesConfig: [
                'r3_developer',
                'r3_admin',
                'r3_support',
                'connect_admin'
            ]
        }), LayoutModule, RouterModule, ReactiveFormsModule, SharedModule, R3CommonModule.forRoot({
            RolesConfig: [
                'r3_developer',
                'r3_admin',
                'r3_support',
                'connect_admin',
                'mcs_admin',
                'mcs_site_admin',
                'mcs_site_user'
            ],
            "Environment": environment
        }), AlertBannerModule, MatDatepickerModule, MatMomentDateModule),
        provideRouter(ROUTES, withComponentInputBinding()),
        DatePipe,
        DecimalPipe,
        PercentPipe,
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true },
        { provide: 'STORAGE_PREFIX', useValue: 'connect' },
        { provide: LOCALE_ID, useValue: 'en' },
        { provide: 'Environment', useValue: environment },
        {
            provide: ApiConfiguration,
            useFactory: () => new ApiConfiguration({
                basePath: environment.apiAddress
            }),
            deps: [],
            multi: false
        },
        AlertBannerService,
        MatSnackBar,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
