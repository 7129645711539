<!-- Loading spinner while data is fetched -->
<div *ngIf="loading">
    <mat-spinner class="krucial-loading-spinner" [style.display]="!device ? 'block' : 'none'"></mat-spinner>
</div>
<div *ngIf="device && !loading" id="deployment-device-container">
    <mat-card appearance="outlined">
        <div id="deployment-device-title-container">
            <button mat-icon-button data-cy="deployment-device-back-arrow" (click)="goToDevicesView()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <h1><span class="desktop-title">{{deployment.name}} / </span>{{device.devEui | AddSpaces}}</h1>
            <div></div>
        </div>
    </mat-card>
    <nav mat-tab-nav-bar #tabGroup mat-align-tabs="center" dynamicHeight>
        <ng-container *ngFor="let tab of tabs">
            <a mat-tab-link
               *userHasRole="tab.userHasRole"
               [active]="activeTab === tab" data-name="{{tab.displayName}}"
               [routerLink]="tab.route" i18n [attr.data-cy]="'deployment-management-device-tab-' + tab.displayName.toLowerCase()">
                {{tab.displayName}}
            </a>
        </ng-container>
    </nav>
    <ng-container *ngIf="!error">
        <router-outlet></router-outlet>
    </ng-container>
</div>
