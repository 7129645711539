import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry, tap} from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap(evt => {
          // Throw an error from Post requests that return false, indicating an error with processings.
          // TODO: Is this the right way to handle this?
/*          // Push to the 500 page?
          if (evt instanceof HttpResponse) {
            if (!evt.body && evt.k-cell-status !== 204) {
              throw new Error();
            }
          }*/
        },
          error => {
          // Perhaps show a popup detailing the failure?
          return throwError(() => 'The event was not saved as the server returned an error.');
        }),
        retry(1),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }
}
