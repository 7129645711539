import {Component, OnInit} from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { RouterOutlet } from '@angular/router';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [NgIf, RouterOutlet]
})
export class AppComponent implements OnInit{
  title = 'connect';
  loading = true;

  constructor(private authenticatorService: AuthenticatorService) {
  }

  ngOnInit(): void {
    this.loading = false;
  }
}
