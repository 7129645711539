<div>
    <ng-container>
        <h1 mat-dialog-title i18n>Set Wi-Fi Credentials</h1>
        <div mat-dialog-content>
            <form [formGroup]="form">
                <div>
                    <mat-form-field>
                        <mat-label i18n>SSID</mat-label>
                        <input data-cy="ssid" matInput type="text" formControlName="ssid" autocomplete="off">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label i18n>Password</mat-label>
                        <input data-cy="password" matInput [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="off">
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </div>
            </form>
        </div>
        <div mat-dialog-actions>
            <button mat-button mat-dialog-close i18n>Cancel</button>
            <button mat-raised-button [disabled]="form.invalid" color="primary"
                    data-cy="save-button" (click)="save()" i18n>
                Save
            </button>
        </div>
    </ng-container>
</div>
