import { Component, OnInit } from '@angular/core';
import {DeploymentDeviceService} from "../deployment-device.service";
import {AppdataMtDtoEncoded, DeviceDto, DeviceService} from "@r3-iot/api-sigma";
import {debounceTime, finalize, take} from "rxjs";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import {AlertBannerService} from "@r3-iot/common";
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor } from '@angular/common';

enum ValueType {
  Text,
  Number,
  Boolean
}

@Component({
    selector: 'app-device-send',
    templateUrl: './device-send.component.html',
    styleUrls: ['./device-send.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, MatCardModule, MatButtonModule, MatDividerModule, MatIconModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgFor, MatSelectModule, MatOptionModule]
})
export class DeviceSendComponent implements OnInit {
  ValueType = ValueType;

  device: DeviceDto;

  sendEncodedDataForm: UntypedFormGroup;
  sendNonEncodedDataForm: UntypedFormGroup;

  showSendEncodedPage: boolean = false;
  showSendNonEncodedPage: boolean = false;

  // List of keyvalue pairs for non-encoded page
  keyValuePairControls: { pairNumber: number, valueType: ValueType, key: UntypedFormControl, value: UntypedFormControl }[] = [];
  currentIndex = 0;

  saving: boolean = false;
  loading: boolean = true;

  // Localisations
  alertBannerSendNonEncodedDataSuccess = $localize`Non-encoded data has been queued.`;
  alertBannerSendEncodedDataSuccess = $localize`Encoded data has been queued.`;
  alertBannerSendDataError = $localize`An error occurred. Your data has not been queued.`;
  alertBannerOk = $localize`OK`;
  alertBannerClearForm = $localize`Clear Form`;

  constructor(private deploymentDeviceService: DeploymentDeviceService, private deviceService: DeviceService,
              private alertBannerService: AlertBannerService) { }

  ngOnInit(): void {
    this.deploymentDeviceService.device$.pipe(take(1)).subscribe({
      next: (device: DeviceDto) => {
        this.device = device;
        this.loading = false;
      }
    })

    this.sendEncodedDataForm = new UntypedFormGroup({
      portNumber: new UntypedFormControl(0, [Validators.required]),
      data: new UntypedFormControl(null, [Validators.required])
    });

    this.resetNonEncodedForm();
  }

  resetNonEncodedForm(): void {
    this.sendNonEncodedDataForm = new UntypedFormGroup({});
    this.keyValuePairControls = [];
    this.currentIndex = 0;
  }

  goBack(): void {
    this.showSendNonEncodedPage = false;
    this.showSendEncodedPage = false;
    this.resetNonEncodedForm();
  }

  //// Methods for sending encoded data
  sendEncodedData(): void {
    this.saving = true;
    const appDataMtDtoEncoded: AppdataMtDtoEncoded = {
      port: this.sendEncodedDataForm.get("portNumber").value,
      data: this.sendEncodedDataForm.get("data").value
    };

    // The API accepts an array, but the UI is limited to one message, so we create an array with 1 length.
    const appDataMtDtoArray: AppdataMtDtoEncoded[] = [];
    appDataMtDtoArray.push(appDataMtDtoEncoded);

    this.deviceService.v1DeviceDevEuiAppdatamtencodedPost(this.device.devEui, appDataMtDtoArray)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.showAlertBanner(this.alertBannerSendEncodedDataSuccess);
          }, error: (err: any) => {
            this.showAlertBanner(this.alertBannerSendDataError);
          }
        })
  }


  //// Methods for sending non-encoded data
  addControlPair(valueType: ValueType): void {
    // Default value is determined by ValueType
    let defaultValue: string | number | boolean;

    switch(valueType) {
      case ValueType.Text:
        defaultValue = '';
        break;
      case ValueType.Number:
        defaultValue = 0;
        break;
      case ValueType.Boolean:
        defaultValue = false;
        break;
      default:
        break;
    }

    this.keyValuePairControls.push({
      pairNumber: this.currentIndex,
      valueType: valueType,
      key: new UntypedFormControl(null, [Validators.required]),
      value: new UntypedFormControl(defaultValue, [Validators.required])
    });

    const control = this.keyValuePairControls.find(x => x.pairNumber === this.currentIndex);
    this.sendNonEncodedDataForm.addControl(`key-${this.currentIndex}`, control.key);
    this.sendNonEncodedDataForm.addControl(`value-${this.currentIndex}`, control.value);

    this.currentIndex += 1;
  }

  deleteControlPair(pairNumber: number): void {
    const index = this.keyValuePairControls.map(x => x.pairNumber).indexOf(pairNumber);
    this.keyValuePairControls.splice(index, 1);
    this.sendNonEncodedDataForm.removeControl(`key-${pairNumber}`);
    this.sendNonEncodedDataForm.removeControl(`value-${pairNumber}`);
  }

  sendNonEncodedData(): void {
    this.saving = true;

    const payload = {};

    for (const keyValuePairControl of this.keyValuePairControls) {
      // keyValuePairControl is a pair of MatFormControls. One for the key, one for the value
      // The value can be either string, number or boolean, depending on the control type
      payload[keyValuePairControl.key.value] = keyValuePairControl.value.value;
    }

    // The API accepts an array, but the UI is limited to one message, so we create an array with 1 length.
    const payloadArray: any[] = [];
    payloadArray.push(payload);

    this.deviceService.v1DeviceDevEuiAppdatamtPost(this.device.devEui, payloadArray)
        .pipe(take(1), finalize(() => { this.saving = false; }))
        .subscribe({
          next: () => {
            this.showAlertBanner(this.alertBannerSendNonEncodedDataSuccess);
          }, error: (err: any) => {
            this.showAlertBanner(this.alertBannerSendDataError);
          }
        })
  }

  showAlertBanner(message: string): void {
    this.alertBannerService.open(message, [this.alertBannerOk, this.alertBannerClearForm])
        .pipe(take(1), finalize(() => { this.saving = false; }))
        .subscribe({
          next: (value: number) => {
            if (value === 1) {
              this.resetNonEncodedForm();
              this.sendEncodedDataForm.reset();
            }
          }
        });
  }
}
