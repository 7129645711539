import {Component, OnInit} from '@angular/core';
import {DeploymentDto, KCellService, SetWifiDto} from "@r3-iot/api-sigma";
import {DeploymentManagementService} from "../../deployment-management.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {finalize, take} from "rxjs";
import {AlertBannerService} from "@r3-iot/common";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {SetWifiCredentialsDialogComponent} from "./set-wifi-credentials-dialog/set-wifi-credentials-dialog.component";
import {TimingsConfigDialogComponent} from "./timings-config-dialog/timings-config-dialog.component";
import {SetTimingsDto} from "@r3-iot/api-sigma/lib/model/setTimingsDto";
import {SendRequestsDialogComponent} from "./send-requests-dialog/send-requests-dialog.component";
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-k-cell-configuration',
    templateUrl: './k-cell-configuration.component.html',
    styleUrls: ['./k-cell-configuration.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, MatCardModule, MatButtonModule, MatIconModule, MatDividerModule]
})
export class KCellConfigurationComponent implements OnInit {

  alertBannerOk = $localize`OK`;
  alertBannerEnableWifiSuccess = $localize`Config change to enable Wi-Fi was queued successfully.`;
  alertBannerSsidAndPasswordNotSet = $localize`Wi-Fi cannot be enabled until an SSID and Password have been set.`;
  alertBannerDisableWifiSuccess = $localize`Config change to disable Wi-Fi was queued successfully.`;
  alertBannerSsidAndPasswordSuccess = $localize`Config change to update Wi-Fi SSID and Password was queued successfully.`
  alertBannerError = $localize`Error communicating with server`;

  deployment: DeploymentDto;
  loading: boolean;

  apiRequestPending: boolean = false;
  enablingWifi: boolean = false;
  disablingWifi: boolean = false;
  settingSsidAndPassword: boolean = false;
  settingConfigTimings: boolean = false;

  constructor(private deploymentManagementService: DeploymentManagementService, private kCelApiService: KCellService,
              private kCellService: KCellService, private alertBannerService: AlertBannerService,
              private dialog: MatDialog) {}

  ngOnInit() {
    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deployment: DeploymentDto) => {
        this.deployment = deployment;
        this.loading = false;
      }
    })
  }

  enableWifi(): void {
    this.enablingWifi = true;
    this.apiRequestPending = true;
    this.kCelApiService.v1KcellSerialNumberConfigWifiEnablePost(this.deployment.kCellSerialNumber)
      .pipe(
        take(1),
        finalize(() => {
          this.enablingWifi = false;
          this.apiRequestPending = false;
        })
      )
      .subscribe({
        next: () => {
          this.alertBannerService.open(this.alertBannerEnableWifiSuccess, [this.alertBannerOk]);
        }, error: (error: any) => {
          if (error.status === 400) {
            this.alertBannerService.open(this.alertBannerSsidAndPasswordNotSet, [this.alertBannerOk]);
          } else {
            this.alertBannerService.open(this.alertBannerError, [this.alertBannerOk]);
          }
        }
      })
  }

  disableWifi(): void {
    this.disablingWifi = true;
    this.apiRequestPending = true;
    this.kCelApiService.v1KcellSerialNumberConfigWifiDisablePost(this.deployment.kCellSerialNumber)
      .pipe(
        take(1),
        finalize(() => {
          this.disablingWifi = false;
          this.apiRequestPending = false;
        })
      )
      .subscribe({
        next: () => {
          this.alertBannerService.open(this.alertBannerDisableWifiSuccess, [this.alertBannerOk]);
        }, error: (error: any) => {
          this.alertBannerService.open(this.alertBannerError, [this.alertBannerOk]);
        }
      })
  }

  setSsidAndPassword(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'krucial-medium-modal-container';
    this.dialog.open(SetWifiCredentialsDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe({
      next: (setWifiDto: SetWifiDto) => {
        if (!setWifiDto) { return; }
        this.settingSsidAndPassword = true;
        this.apiRequestPending = true;

        this.kCelApiService.v1KcellSerialNumberConfigWifiPut(this.deployment.kCellSerialNumber, setWifiDto)
          .pipe(
            take(1),
            finalize(() => {
              this.settingSsidAndPassword = false;
              this.apiRequestPending = false;
            })
          )
          .subscribe({
            next: () => {
              this.alertBannerService.open(this.alertBannerSsidAndPasswordSuccess, [this.alertBannerOk]);
            }, error: (error: any) => {
              this.alertBannerService.open(this.alertBannerError, [this.alertBannerOk]);
            }
          })
      }
    })
  }

  openViewConfigTimingsDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'krucial-large-modal-container';
    dialogConfig.data = {
      serialNumber: this.deployment.kCellSerialNumber
    };
    this.dialog.open(TimingsConfigDialogComponent, dialogConfig).afterClosed()
        .pipe(take(1)).subscribe({
      next: (timingsDto: SetTimingsDto) => {
        if (!timingsDto) { return; }
        this.settingConfigTimings = true;
        this.apiRequestPending = true;
        this.kCellService.v1KcellSerialNumberConfigTimingsPost(this.deployment.kCellSerialNumber, timingsDto)
            .pipe(take(1),
              finalize(() => {
                this.settingConfigTimings = false;
                this.apiRequestPending = false;
              })
            )
            .subscribe({
              next: () => {
                this.alertBannerService.open($localize`K-Cell timings updated.`, [$localize`OK`]);
              },
              error: () => {
                this.alertBannerService.open($localize`An error occurred while saving your changes.`, [$localize`OK`]);
              }
            })
      }
    });
  }

  openSendRequestsDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'krucial-large-modal-container';
    dialogConfig.data = {
      serialNumber: this.deployment.kCellSerialNumber
    };
    this.dialog.open(SendRequestsDialogComponent, dialogConfig).afterClosed()
        .pipe(take(1)).subscribe({
      next: res => {}
    });
  }
}
