<div class="krucial-table-container">
    <mat-table #deviceDataTable [dataSource]="deviceDataTableSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Message">
            <mat-header-cell *matHeaderCellDef i18n> Message</mat-header-cell>
            <mat-cell *matCellDef="let deviceData"> {{deviceData.message}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="DevEUI">
            <mat-header-cell *matHeaderCellDef i18n> DevEUI</mat-header-cell>
            <mat-cell *matCellDef="let deviceData"> {{deviceData.deviceDevEui}}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="Created At">
            <mat-header-cell *matHeaderCellDef i18n> Created At</mat-header-cell>
            <mat-cell *matCellDef="let deviceData"> {{deviceData.createdAt | date:'d/MM/yyyy HH:mm:ss'}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedDeviceDataColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedDeviceDataColumns;"></mat-row>
    </mat-table>
    <mat-paginator #deviceDataPaginator [length]="deviceDataTableSource.totalRecords()" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
