<div class="margin-wrapper">
    <!-- Loading spinner view -->
    <ng-container *ngIf="loading">
        <h1 mat-dialog-title i18n>Loading Config Details</h1>
        <div mat-dialog-content class="column-layout-align-center">
            <div>
                <mat-spinner class="load-spinner" diameter="24"></mat-spinner>
            </div>
        </div>
    </ng-container>
    <div id="gateway-management-configuration-container" *ngIf="!loading">
        <div mat-dialog-content>
            <form [formGroup]="timingsForm">
                <mat-card>
                    <mat-form-field class="w-100">
                        <mat-label i18n>App Data Uplink Period</mat-label>
                        <input data-cy="data-uplink-period" matInput type="text" formControlName="appDataUplinkPeriod" placeholder="Enter number between 5 and 720 minutes" i18n-placeholder>
                    </mat-form-field>
                    <br>
                    <mat-form-field class="w-100">
                        <mat-label i18n>Status Update Period</mat-label>
                        <mat-select  formControlName="statusUpdatePeriod"
                                     data-cy="status-update-period-select">
                            <mat-option [value]="180" i18n data-cy="status-update-period-option-0">every 3 hours</mat-option>
                            <mat-option [value]="360" i18n data-cy="status-update-period-option-1">every 6 hours</mat-option>
                            <mat-option [value]="540" i18n data-cy="status-update-period-option-2">every 9 hours</mat-option>
                            <mat-option [value]="720" i18n data-cy="status-update-period-option-3">every 12 hours</mat-option>
                        </mat-select>
                        <mat-hint align="end" i18n>Select option</mat-hint>
                    </mat-form-field>
                    <br>
                    <br>
                    <mat-form-field class="w-100">
                        <mat-label i18n>Housekeeping Update Period</mat-label>
                        <mat-select  formControlName="housekeepingUpdatePeriod"
                                     data-cy="housekeeping-update-period-select">
                            <mat-option [value]="180" i18n data-cy="housekeeping-update-period-option-0">every 3 hours</mat-option>
                            <mat-option [value]="360" i18n data-cy="housekeeping-update-period-option-1">every 6 hours</mat-option>
                            <mat-option [value]="540" i18n data-cy="housekeeping-update-period-option-2">every 9 hours</mat-option>
                            <mat-option [value]="720" i18n data-cy="housekeeping-update-period-option-3">every 12 hours</mat-option>
                        </mat-select>
                        <mat-hint align="end" i18n>Select option</mat-hint>
                    </mat-form-field>
                    <br>
                    <div class="krucial-space-betwe en-title-value-tall">
                        <mat-form-field class="w-100">
                            <mat-label i18n>Max Mt Polling Period</mat-label>
                            <mat-select  formControlName="maxMtPollingPeriod"
                                         data-cy="max-mt-polling-period-select">
                                <mat-option [value]="15" i18n data-cy="max-mt-polling-period-option-0">every 15 mins</mat-option>
                                <mat-option [value]="30" i18n data-cy="max-mt-polling-period-option-1">every 30 mins</mat-option>
                                <mat-option [value]="45" i18n data-cy="max-mt-polling-period-option-2">every 45 mins</mat-option>
                                <mat-option [value]="60" i18n data-cy="max-mt-polling-period-option-3">every 1 hour</mat-option>

                                <mat-option [value]="75" i18n data-cy="max-mt-polling-period-option-4">every 1 hour 15 mins</mat-option>
                                <mat-option [value]="90" i18n data-cy="max-mt-polling-period-option-5">every 1 hour 30 mins</mat-option>
                                <mat-option [value]="105" i18n data-cy="max-mt-polling-period-option-6">every 1 hour 45 mins </mat-option>
                                <mat-option [value]="120" i18n data-cy="max-mt-polling-period-option-7">every 2 hours</mat-option>

                                <mat-option [value]="135" i18n data-cy="max-mt-polling-period-option-8">every 2 hour 15 mins</mat-option>
                                <mat-option [value]="150" i18n data-cy="max-mt-polling-period-option-9">every 2 hour 30 mins</mat-option>
                                <mat-option [value]="165" i18n data-cy="max-mt-polling-period-option-10">every 2 hour 45 mins </mat-option>
                                <mat-option [value]="180" i18n data-cy="max-mt-polling-period-option-11">every 3 hours</mat-option>
                            </mat-select>
                            <mat-hint align="end" i18n>Select option</mat-hint>
                        </mat-form-field>
                    </div>
                </mat-card>
            </form>
        </div>
        <div mat-dialog-actions>
            <button mat-button mat-dialog-close i18n>Cancel</button>
            <button mat-raised-button  color="primary" [disabled]="timingsForm.invalid"
                    data-cy="save-button" (click)="save()" i18n>
                Save
            </button>
        </div>
    </div>
</div>
