import {Component, Inject, OnInit} from '@angular/core';
import {DeploymentDto, DeploymentService, KCellDto, ReplaceKCellDto} from "@r3-iot/api-sigma";
import {Observable, take} from "rxjs";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import {map, startWith} from "rxjs/operators";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-select-replacement-gateway-dialog',
    templateUrl: './select-replacement-gateway-dialog.component.html',
    styleUrls: ['./select-replacement-gateway-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, NgFor, MatOptionModule, NgIf, MatButtonModule, MatProgressSpinnerModule, AsyncPipe]
})
export class SelectReplacementGatewayDialogComponent implements OnInit {

  // Data passed into modal
  deployment: DeploymentDto;
  currentGatewaySerialNumber: string;
  allGateways: KCellDto[];

  form: UntypedFormGroup;
  filteredGateways: Observable<KCellDto[]>;
  saving = false;
  constructor(private dialogRef: MatDialogRef<SelectReplacementGatewayDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private deploymentApiService: DeploymentService) {}

  ngOnInit(): void {
    this.deployment = this.data.deployment;
    this.currentGatewaySerialNumber = this.data.currentGatewaySerialNumber;
    this.allGateways = this.data.gateways;

    this.form = new UntypedFormGroup({
      replacementGatewayInput: new UntypedFormControl(null, [Validators.required])
    });

    this.filteredGateways = this.form.controls.replacementGatewayInput.valueChanges.pipe(
      startWith(''),
      map((serialNumber: string) => (serialNumber ? this._filter(serialNumber) : this.allGateways.slice()))
    );
  }

  displayFn(gateway: KCellDto): string {
    if (!gateway) { return "" }
    return gateway.serialNumber.toUpperCase();
  }

  confirm(): void {
    this.dialogRef.disableClose = true;

    const payload: ReplaceKCellDto = {
      newKCellSerialNumber: this.form.controls.replacementGatewayInput.value.serialNumber.toLowerCase()
    };

    this.deploymentApiService.v1DeploymentNameKCellPut(this.deployment.name, payload)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.dialogRef.close('success');
        }, error: (error: any) => {
          this.dialogRef.close(error)
        }
      })
  }

  private _filter(serialNumber: string): KCellDto[] {
    const filterValue = serialNumber.toLowerCase();

    return this.allGateways.filter((kCellDto: KCellDto) => kCellDto.serialNumber.toLowerCase().includes(filterValue));
  }
}
