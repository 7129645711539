import {Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {KCellDto, KCellService, OperationsService} from "@r3-iot/api-sigma";
import {$localize} from "@angular/localize/init";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import {AlertBannerService} from "@r3-iot/common";
import {finalize, take} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-send-requests-dialog',
    templateUrl: './send-requests-dialog.component.html',
    styleUrls: ['./send-requests-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatDialogModule, MatProgressSpinnerModule, MatCardModule, MatButtonModule, MatIconModule, MatDividerModule]
})
export class SendRequestsDialogComponent implements OnInit {

  loading: boolean = true;
  gateway: KCellDto;
  alertBoxOKMessage = $localize`OK`;
  alertBannerDismiss = $localize`Dismiss`;

  constructor( private dialog: MatDialog,  private alertBannerService: AlertBannerService, private matDialogRef: MatDialogRef<SendRequestsDialogComponent>,
               private kCellService: KCellService, private kCellOperationsService: OperationsService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.kCellService.v1KcellSerialNumberGet(this.data.serialNumber).pipe(untilDestroyed(this), finalize(() => {
      this.loading = false
    })).subscribe({
      next: (gateway: KCellDto) => {
        if (!gateway) {
          return;
        }
        this.gateway = gateway;
      },
      error: (err: any) => {}
    })
  }

  sendPacketRequest(): void {
    this.kCellOperationsService.v1KcellSerialNumberOperationsRequeststatusPost(this.gateway.serialNumber)
        .pipe(
            take(1)
        ).subscribe({
      next: () => {
        this.alertBannerService.open($localize`Packet request queued successfully`, [this.alertBoxOKMessage])
        this.matDialogRef.close();
        // }
      }, error: () => {
        this.alertBannerService.open($localize`An error occurred. Your packet has not been queued.`, [this.alertBoxOKMessage])
      }
    })
  }

  sendRebootPacketRequest(): void {
    this.kCellOperationsService.v1KcellSerialNumberOperationsRequestrebootPost(this.gateway.serialNumber)
        .pipe(
            take(1)
        ).subscribe({
      next: () => {
        this.alertBannerService.open( $localize`Packet request queued successfully`, [this.alertBoxOKMessage])
        this.matDialogRef.close();
      }, error: () => {
        this.alertBannerService.open($localize`An error occurred. Your packet has not been queued.`, [this.alertBoxOKMessage])
      }
    })
  }

}
