import {Component, ElementRef, Inject, OnInit, ViewChildren} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-edit-tag-dialog',
    templateUrl: './edit-tag-dialog.component.html',
    styleUrls: ['./edit-tag-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, NgIf, MatProgressSpinnerModule]
})
export class EditTagDialogComponent implements OnInit {
  @ViewChildren("valueField") valueField: ElementRef;

  form: UntypedFormGroup;
  saving = false;

  constructor(public dialogRef: MatDialogRef<EditTagDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      key: new UntypedFormControl(this.data ? this.data.key : '', [Validators.required]),
      value: new UntypedFormControl(this.data ? this.data.value : '', [Validators.required]),
    });

  }

  save(): void {
    if (this.form.invalid) { return; }
    this.saving = true;
    let res = {key: this.form.controls.key.value, value: this.form.controls.value.value}
    this.dialogRef.close(res);
  }

}