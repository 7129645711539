import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, take} from 'rxjs';
import {Router} from "@angular/router";
import {DeploymentDto, DeploymentService} from "@r3-iot/api-sigma";
import {tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class DeploymentManagementService implements OnDestroy {

    // Observable for the currently selected cell
    protected pDeployment: BehaviorSubject<DeploymentDto> = new BehaviorSubject(null as unknown as DeploymentDto);
    public readonly deployment$: Observable<DeploymentDto> = this.pDeployment.asObservable();

    // Error observable used by child components to bubble the error up to the parent GatewayManagement component
    protected pOnLoadError: BehaviorSubject<any> = new BehaviorSubject(null);
    public readonly onLoadError$: Observable<any> = this.pOnLoadError.asObservable();


    constructor(public deploymentApiService: DeploymentService, public router: Router) {}

    getDeployment(deploymentName: string): Observable<DeploymentDto> {
        return this.deploymentApiService.v1DeploymentNameGet(deploymentName)
            .pipe(
                take(1),
                tap((deployment: DeploymentDto) => this.pDeployment.next(deployment)),
            );
    }

    refresh(): void {
        this.getDeployment(this.pDeployment.value.name).subscribe({});
    }

    raiseOnLoadError(error): void {
        this.pOnLoadError.next(error);
    }

    ngOnDestroy() {
        this.pDeployment.unsubscribe();
    }
}
