import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {R3CommonModule} from '@r3-iot/common';
import {environment} from "../../environments/environment";


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    R3CommonModule.forRoot({
        RolesConfig: [
            'r3_developer',
            'r3_admin',
            'r3_support',
            'connect_admin',
            'connect_site_admin',
            'connect_site_user'
        ],
        "Environment": environment
    })
],
    exports: [

    ],
  providers: []
})
export class SharedModule { }
