import {Component, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import {SetWifiDto} from "@r3-iot/api-sigma";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-set-wifi-credentials-dialog',
    templateUrl: './set-wifi-credentials-dialog.component.html',
    styleUrls: ['./set-wifi-credentials-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule]
})
export class SetWifiCredentialsDialogComponent implements OnInit {
  hide: boolean = true;
  form: UntypedFormGroup;

  constructor(private matDialogRef: MatDialogRef<SetWifiCredentialsDialogComponent>) {
  }
  
  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      ssid: new UntypedFormControl(null, [Validators.required]),
      password: new UntypedFormControl(null, [Validators.required])
    });
  }
  
  save(): void {
    const setWifiDto: SetWifiDto = {
      wifiSsid: this.form.get("ssid").value,
      wifiPassword: this.form.get("password").value
    }

    this.matDialogRef.close(setWifiDto);
  }
}
