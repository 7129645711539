import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {
  DeploymentDto,
  DeploymentService,
  DeviceDto, DeviceProfileDto,
  DeviceProfileService,
  DeviceService,
  QueueDto, RemoveDeviceFromDeploymentDto
} from "@r3-iot/api-sigma";
import { AlertBannerService, R3CommonModule } from "@r3-iot/common";
import {take} from "rxjs";
import {Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import { MatTable, MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DevicesViewSource} from "./devices-view.source";
import {DeploymentManagementService} from "../../deployment-management.service";
import {
  DeleteAcknowledgeDialogComponent
} from "../../../../../shared/dialogs/delete-acknowledge-dialog/delete-acknowledge-dialog.component";
import {NewDeviceDialogComponent} from "./new-device-dialog/new-device-dialog.component";
import {EditDeviceDialogComponent} from "./edit-device-dialog/edit-device-dialog.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";


@UntilDestroy()
@Component({
    selector: 'app-devices-view',
    templateUrl: './devices-view.component.html',
    styleUrls: ['./devices-view.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, MatTableModule, MatPaginatorModule, R3CommonModule]
})

export class DevicesViewComponent implements AfterViewInit {
  @ViewChild('devicesTable') devicesTable : MatTable<string>;
  @ViewChild('devicePaginator', { static: true }) devicePaginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;
  alertBannerDismiss = $localize`Dismiss`;
  alertBannerOk = $localize`OK`;
  alertBannerDeviceRemovedAndDeleted = $localize`Device removed from deployment and deleted`;
  alertBannerRemoveError = $localize`Error communicating with server when removing device from deployment`;
  alertBannerDeleteError = $localize`Error communicating with server when deleting device`;
  data: any;
  deviceDataSource = new MatTableDataSource<DeviceDto>();
  form: UntypedFormGroup;
  deployment: DeploymentDto;
  queue: QueueDto[];
  saving = false;
  loading = true;
  devicesViewDataSource: DevicesViewSource;
  displayedDeviceColumns=['DevEUI', 'Name',  'Operations']


  constructor(private router: Router, private dialog: MatDialog, private deploymentManagementService: DeploymentManagementService,
              private deviceProfileService: DeviceProfileService, private deploymentsService: DeploymentService,
              private deviceService: DeviceService, public alertBannerService: AlertBannerService) {
    this.devicesViewDataSource = new DevicesViewSource();

  }
  ngAfterViewInit() {
    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deployment: DeploymentDto) => {
        if (!deployment) { return; }
        this.deployment = deployment;

        this.deviceDataSource.data = deployment.devices;
        this.deviceDataSource.paginator = this.devicePaginator;
        this.deviceDataSource.sort = this.sort;
        this.loading = false;
        this.renderDevicesTable();
      }
    })
  }

  deleteRow(event, deviceList: string[]): void {
    event.stopPropagation();

    const payload: RemoveDeviceFromDeploymentDto = {
      deviceDevEuis: deviceList
    };

    this.deploymentsService.v1DeploymentNameDeviceDelete(this.deployment.name, payload)
      .pipe(take(1)).subscribe({
      next: () => {
        this.deviceService.v1DeviceDevEuiDelete(deviceList[0]).pipe(take(1)).subscribe({
          next: () => {
            const elementPos = this.deviceDataSource.data.map(x => x.devEui).indexOf(deviceList[0]);
            if (elementPos > -1){
              this.deviceDataSource.data.splice(elementPos, 1);
            }
            this.renderDevicesTable();
            this.alertBannerService.open(this.alertBannerDeviceRemovedAndDeleted, [this.alertBannerOk]);
          },
          error: () => {
            this.alertBannerService.open(this.alertBannerDeleteError, [this.alertBannerDismiss]);
          }
        });
      },
      error: () => {
        this.alertBannerService.open(this.alertBannerRemoveError, [this.alertBannerDismiss]);
      }
    });
  }

  openAddDeviceToDeploymentDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'krucial-large-modal-container';
    dialogConfig.data = {
      deployment: this.deployment
    }
    this.dialog.open(NewDeviceDialogComponent, dialogConfig).afterClosed()
      .pipe(take(1)).subscribe({
      next: res => {
        if(res){
          this.deviceDataSource.data.push(res);
          this.renderDevicesTable();
        }
      }
    });
  }

  openDeleteDeviceDialog(event, devEui: string): void {
    event.stopPropagation();
    const deviceList = [];
    deviceList.push(devEui);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Delete Device ' + devEui,
      recordType: 'deviceDto',
      optionalMessage: 'This action can not be undone.'
    };
    this.dialog.open(DeleteAcknowledgeDialogComponent, dialogConfig).afterClosed()
      .pipe(take(1))
      .subscribe({
        next: response => {
          if (response === 'delete'){
            this.deleteRow(event, deviceList);
          }
        }
      });
  }

  goToDeploymentDevice(event, devEui: string): void {
    event.stopPropagation();
    this.router.navigate([`/home/deployments/${this.deployment.name}/devices/${devEui}/details`]);
  }

  renderDevicesTable(): void {
    this.deviceDataSource.paginator = this.devicePaginator;
    this.deviceDataSource.sort = this.sort;

    if (this.devicesTable) { return; }
    this.devicesTable.renderRows();
  }

  applyDeviceFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(filterValue != null){
      this.deviceDataSource.filter = filterValue.trim().toLowerCase();
      if (this.deviceDataSource.paginator) {
        this.deviceDataSource.paginator.firstPage();
      }
    }
  }

  goToDeployments(): void {
    this.router.navigate(['home/deployments']);
  }
}
