<div class="krucial-table-container">
    <mat-form-field>
        <input data-cy="queue-filter" matInput
               placeholder="Filter Data by Action Type, Status or DevEUI" i18n-placeholder>
    </mat-form-field>

    <mat-table #queueTable [dataSource]="queueDataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Action Type">
            <mat-header-cell *matHeaderCellDef i18n> Action Type</mat-header-cell>
            <mat-cell *matCellDef="let queue"> {{queue?.actionType ? queue?.actionType : '--'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="DevEUI">
            <mat-header-cell *matHeaderCellDef i18n> DevEUI</mat-header-cell>
            <mat-cell *matCellDef="let queue"> {{queue?.devEui ? (queue?.devEui | AddSpaces) : '--'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Status">
            <mat-header-cell *matHeaderCellDef i18n> Status</mat-header-cell>
            <mat-cell *matCellDef="let queue"> {{queue?.status ? queue?.status : '--'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Created At">
            <mat-header-cell *matHeaderCellDef i18n> Created At</mat-header-cell>
            <mat-cell *matCellDef="let queue"> {{queue?.createdAt ? (queue?.createdAt
                | date:'yyyy/MM/dd HH:mm:ss') : '--'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Completed At">
            <mat-header-cell *matHeaderCellDef i18n> Completed At</mat-header-cell>
            <mat-cell *matCellDef="let queue"> {{queue?.completedAt ? (queue?.completedAt
                | date:'yyyy/MM/dd HH:mm:ss') : '--'}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedQueueColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedQueueColumns;"></mat-row>
    </mat-table>
    <mat-paginator #queuePaginator [length]="queueDataSource.totalRecords()"
                   [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
