import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {fetchAuthSession} from "@aws-amplify/auth";
import {AuthenticatorService} from "@aws-amplify/ui-angular";

// CellService extends the Site Store service to add 'Currently Selected' functionality.
// CellService should be used instead of SiteStore across the application.
@Injectable({
  providedIn: 'root'
})

export class SessionService {
  constructor(private router: Router,
              private authenticatorService: AuthenticatorService) {}

  async login(): Promise<void> {
    await this.refresh();
  }

  async sessionExpired(): Promise<Boolean> {
    return fetchAuthSession()
      .then(authSession => {
        // return user.tokenExpired(user);
        return this.tokenExpired(authSession)
      });
  }

  private tokenExpired(authSession: any): boolean{

    const expiration = authSession.tokens.accessToken.payload.exp;

    const currentUnixTime = new Date().getTime() / 1000

    if (currentUnixTime > expiration) {
      return true;
    }
    else {
      return false;
    }
  }

  async refresh(): Promise<void> {
   await fetchAuthSession({forceRefresh: true}).then(() => {});
  }

  async signOut(): Promise<void> {
    this.authenticatorService.signOut();
  }
}
