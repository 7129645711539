<div id="k-cell-operations-container">
    <mat-card appearance="outlined" class="k-cell-operations-card">
        <div class="krucial-space-between-title-value-tall">
            <div i18n><b>Replace K-Cell</b></div>
            <button mat-stroked-button
                    (click)="openReplaceGatewayModal()"
                    data-cy="open-replace-gateway-modal-button" i18n>Replace <mat-icon  class="replace-button-icon">move_up</mat-icon></button>
        </div>
        <div class="info-text">
            <div i18n>Replace this deployment's K-Cell with a different K-Cell.</div>
            <div i18n>The active K-Cell's configuration and connected devices will be transferred to the replacement K-Cell.</div>
            <div i18n>This process is not instantaneous. It will take some time for your devices to connect and begin transmitting data.</div>
        </div>
    </mat-card>
</div>