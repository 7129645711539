<div>
    <ng-container *ngIf="filteredDeviceProfiles.length === 0">
        <h1 mat-dialog-title i18n>No device profiles found to swap to</h1>
        <div mat-dialog-content>
            <div i18n> First create a device profile with the same activation mode</div>
            <br>
            <button mat-raised-button color="primary" (click)="goToDeviceProfiles()" i18n>Go to Device Profiles</button>
        </div>
    </ng-container>


    <ng-container *ngIf="filteredDeviceProfiles.length > 0">
        <h1 mat-dialog-title i18n>Swap {{currentDeviceProfile?.name}} Profile</h1>
        <div mat-dialog-content>
            <br>
            <form [formGroup]="form">
                <div>
                    <mat-form-field>
                        <mat-label i18n>Device Profile</mat-label>
                        <mat-select data-cy="Device-Profile-Select"  formControlName="deviceProfileSelect">
                            <mat-option *ngFor="let filteredDeviceProfile of filteredDeviceProfiles"
                                        [attr.data-device-profile-dropdown]="filteredDeviceProfile.name"
                                        [value]="filteredDeviceProfile">{{filteredDeviceProfile.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>
        <div mat-dialog-actions>
            <button mat-button mat-dialog-close i18n>Cancel</button>
            <button mat-raised-button *ngIf="!saving" [disabled]="form.invalid" color="primary" (click)="save()" i18n>
                Save
            </button>
            <div class="center-row" *ngIf="saving">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
        </div>
    </ng-container>
</div>
