<div>
    <h1 mat-dialog-title i18n>New Tag</h1>
    <div mat-dialog-content>
        <form [formGroup]="form">
            <div>
                <mat-form-field>
                    <mat-label i18n>Key</mat-label>
                    <input matInput type="text" formControlName="key" data-cy="input-tag-key">
                    <mat-hint align="end" i18n>Enter alphanumeric characters</mat-hint>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label i18n>Value</mat-label>
                    <input matInput type="text" formControlName="value" data-cy="input-tag-value">
                    <mat-hint align="end" i18n>Enter alphanumeric characters</mat-hint>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close data-cy="cancel-save-tag-button" i18n>Cancel</button>
        <button mat-raised-button *ngIf="!saving" [disabled]="form.invalid" color="primary" (click)="save()"
                data-cy="save-tag-button" i18n>
            Save
        </button>
        <div class="center-row" *ngIf="saving">
            <mat-spinner diameter="24"></mat-spinner>
        </div>
    </div>
</div>

