import {Component, OnInit} from "@angular/core";
import {DeploymentDto, KCellLatestStatusDto, KCellService} from "@r3-iot/api-sigma";
import {finalize, take} from "rxjs";
import {DeploymentManagementService} from "../../deployment-management.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { MatDividerModule } from "@angular/material/divider";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgIf, DatePipe } from "@angular/common";
@UntilDestroy()
@Component({
    selector: 'app-k-cell-status',
    templateUrl: './k-cell-status.component.html',
    styleUrls: ['./k-cell-status.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, MatCardModule, MatDividerModule, DatePipe]
})

export class KCellStatusComponent implements OnInit{

  gatewayLatestStatus: KCellLatestStatusDto;
  loading: boolean = true;
  chargeStatus: string;
  minutesAgoText: string = $localize`minutes ago`;
  acChargingText: string = $localize`Charging (AC)`;
  dcChargingText: string = $localize`Charging (DC)`;
  notChargingText: string = $localize`Not charging`;

  constructor(private deploymentManagementService: DeploymentManagementService,
              private kcellApiService: KCellService) {}

  ngOnInit(): void {
    this.deploymentManagementService.deployment$.pipe((untilDestroyed(this))).subscribe({
      next: (deploymentDto: DeploymentDto) => {
        if (!deploymentDto) { return; }

        this.kcellApiService.v1KcellSerialNumberStatusGet(deploymentDto.kCellSerialNumber)
          .pipe(take(1), finalize(() => {
            this.loading = false;
          })).subscribe({
          next: (gatewayLatestStatusDto: KCellLatestStatusDto) => {
            if (!gatewayLatestStatusDto) { return; }

            this.gatewayLatestStatus = gatewayLatestStatusDto;
            if (this.gatewayLatestStatus.acCharging)
              this.chargeStatus = this.acChargingText;
            else if (this.gatewayLatestStatus.dcCharging)
              this.chargeStatus = this.dcChargingText;
            else if (this.gatewayLatestStatus.notCharging)
              this.chargeStatus = this.notChargingText;
          }
        });
      }
    })
  }
}
