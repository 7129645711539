import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-new-tag-dialog',
    templateUrl: './new-tag-dialog.component.html',
    styleUrls: ['./new-tag-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, NgIf, MatProgressSpinnerModule]
})
export class NewTagDialogComponent implements OnInit {
  form: UntypedFormGroup;
  saving = false;
  textRegExp = /^[a-zA-Z0-9-_]+$/; // a to z, A to Z, numbers along with underscore and dash

  constructor(public dialogRef: MatDialogRef<NewTagDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      key: new UntypedFormControl(this.data ? this.data.key : '', [Validators.required, Validators.pattern(this.textRegExp)]),
      value: new UntypedFormControl(this.data ? this.data.value : '', [Validators.required]),
    });
  }

  save(): void {
    if (this.form.invalid) { return; }
    this.saving = true;
    let res = {key: this.form.controls.key.value, value: this.form.controls.value.value}
    this.dialogRef.close(res);
  }

}
