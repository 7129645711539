import {Component, Host, OnInit} from '@angular/core';
import {DeploymentDto, DeviceDto, DeviceService} from "@r3-iot/api-sigma";
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from "@angular/router";
import {finalize, take} from "rxjs";
import { NavItem, R3CommonModule } from "@r3-iot/common";
import { TitleCasePipe, NgIf, NgFor } from "@angular/common";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DeploymentDeviceService} from "./deployment-device.service";
import {DeploymentManagementService} from "../../../deployment-management.service";
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@UntilDestroy()
@Component({
    selector: 'app-deployment-management-device',
    templateUrl: './deployment-device.component.html',
    styleUrls: ['./deployment-device.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, MatCardModule, MatButtonModule, MatIconModule, MatTabsModule, NgFor, R3CommonModule, RouterLink, RouterOutlet]
})
export class DeploymentDeviceComponent implements OnInit {


    menuDetails = $localize`Details`;
    menuData = $localize`Data`;
    menuSendData = $localize`Send`;

    tabs: NavItem[] = [
        {
            displayName: this.menuDetails,
            iconName: '',
            route: `./details`,
            userHasRole: '',
            children: [],
        },
        {
            displayName: this.menuData,
            iconName: '',
            route: `./data`,
            userHasRole: '',
            children: [],
        },
        {
            displayName: this.menuSendData,
            iconName: '',
            route: `./send`,
            userHasRole: '',
            children: [],
        }
    ]
    activeTab: NavItem;
    device: DeviceDto;
    deployment: DeploymentDto;

    loading: boolean;
    error: any;

    constructor(private route: ActivatedRoute, public deploymentDeviceService: DeploymentDeviceService,
        private router: Router, private titleCasePipe: TitleCasePipe,
                private deploymentManagementService: DeploymentManagementService) { }

    ngOnInit(): void {
        this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
            next: (deployment: DeploymentDto) => {
                this.deployment = deployment;
                const route: string = this.titleCasePipe.transform(this.router.url.split('/').pop()) as string;
                this.activeTab = this.tabs.find(x => x.displayName.toLowerCase() === route.toLowerCase()) as NavItem;

                // This is the top level component. On load, we grab the ID from the URL and load that Device
                // into the Service. Each child component subsequently refers to the service to find the current device.
                this.route.params.pipe(untilDestroyed(this)).subscribe({
                    next: params => {
                        this.deploymentDeviceService.getDevice(params.devEui)
                          .pipe(
                            take(1),
                            finalize(() => { this.loading = false; })
                          ).subscribe({
                            next: (device: DeviceDto) => {
                                this.device = device;
                            }
                        });
                    }
                })
            }
        })

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                const route: string = this.titleCasePipe.transform(this.router.url.split('/').pop()) as string;
                this.activeTab = this.tabs.find(x => x.displayName.toLowerCase() === route.toLowerCase()) as NavItem;
            }
        });
    }

    goToDevicesView(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
