import {mapToCanActivate, Routes } from '@angular/router';
import {AuthorizationGuard} from './shared/guards/auth.guard';
import {DEPLOYMENT_MANAGEMENT_ROUTES} from "./components/deployments/deployment-management/routes";
import {PLUGIN_MANAGEMENT_ROUTES} from "./components/plugins/plugin-management/routes";
import {
    DEVICE_PROFILE_DETAIL_ROUTES
} from "./components/device-profiles/device-profile-details/device-profile-details-routing.module";

export const ROUTES: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadComponent: () => import('./components/login/login.component').then(mod => mod.LoginComponent) },
  { path: 'logout', loadComponent: () => import('./components/logout/logout.component').then(mod => mod.LogoutComponent)},
  { path: 'home', loadComponent: () => import('./components/home/home.component').then(mod => mod.HomeComponent), children:
    [
      { path: 'deployments', loadComponent: () => import('./components/deployments/deployments.component').then(mod => mod.DeploymentsComponent),
          canActivate: mapToCanActivate([AuthorizationGuard])},
      { path: 'devices', loadComponent: () => import('./components/devices/connect-devices.component').then(mod => mod.ConnectDevicesComponent),
          canActivate: mapToCanActivate([AuthorizationGuard])},
      { path: 'plugins', loadComponent: () => import('./components/plugins/plugins.component').then(mod => mod.PluginsComponent),
          canActivate: mapToCanActivate([AuthorizationGuard])},
      { path: 'plugins/:name', loadComponent: () => import('./components/plugins/plugin-management/plugin-management.component').then(mod => mod.PluginManagementComponent),
          loadChildren: () => PLUGIN_MANAGEMENT_ROUTES,
          canActivate: mapToCanActivate([AuthorizationGuard])},
      { path: 'deployments/:name', loadComponent: () => import('./components/deployments/deployment-management/deployment-management.component').then(mod => mod.DeploymentManagementComponent),
          loadChildren: () => DEPLOYMENT_MANAGEMENT_ROUTES},
      { path: 'web-hooks', loadComponent: () => import('./components/web-hooks/web-hooks.component').then(mod => mod.WebHooksComponent),
          canActivate: mapToCanActivate([AuthorizationGuard])},
      { path: 'web-hooks/:name', loadComponent: () => import('./components/web-hooks/web-hook-post-response/web-hook-post-response.component').then(mod => mod.WebHookPostResponseComponent),
          canActivate: mapToCanActivate([AuthorizationGuard])},
      { path: 'device-profiles', loadComponent: () => import('./components/device-profiles/device-profiles.component').then(mod => mod.DeviceProfilesComponent),
          canActivate: mapToCanActivate([AuthorizationGuard])},
      { path: 'device-profiles/:name', loadComponent: () => import('./components/device-profiles/device-profile-details/device-profile-details.component').then(mod => mod.DeviceProfileDetailsComponent),
          loadChildren: () => DEVICE_PROFILE_DETAIL_ROUTES,
          canActivate: mapToCanActivate([AuthorizationGuard])},
      { path: 'devices', loadComponent: () => import('./components/devices/connect-devices.component').then(mod => mod.ConnectDevicesComponent),
          canActivate: mapToCanActivate([AuthorizationGuard])},
      { path: 'api-keys', loadComponent: () => import('./components/api-keys/api-keys.component').then(mod => mod.ApiKeysComponent),
          canActivate: mapToCanActivate([AuthorizationGuard])}
    ]
  },
  { path: '404', loadComponent: () => import('./components/error-pages/not-found/not-found.component').then(mod => mod.NotFoundComponent)},
  { path: '500', loadComponent: () => import('./components/error-pages/server-error/server-error.component').then(mod => mod.ServerErrorComponent) },
  { path: '**', redirectTo: '/404', pathMatch: 'full'} // Wildcard route for a 404 page
];
