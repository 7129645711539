import {mapToCanActivate, Routes} from "@angular/router";
import {AuthorizationGuard} from "../../../../shared/guards/auth.guard";
export const K_CELL_ROUTES: Routes = [
    { path: '', redirectTo: 'status', pathMatch: 'full'},
    { path: 'status', loadComponent: () => import('./k-cell-status/k-cell-status.component').then(mod => mod.KCellStatusComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'housekeeping', loadComponent: () => import('./k-cell-housekeeping/k-cell-housekeeping.component').then(mod => mod.KCellHousekeepingComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'queue', loadComponent: () => import('./k-cell-queue/k-cell-queue.component').then(mod => mod.KCellQueueComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'configuration', loadComponent: () => import('./k-cell-configuration/k-cell-configuration.component').then(mod => mod.KCellConfigurationComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'operations', loadComponent: () => import('./k-cell-operations/k-cell-operations.component').then(mod => mod.KCellOperationsComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
];