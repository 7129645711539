<div id="k-cell-status-container">
    <div *ngIf="loading">
        <mat-spinner class="krucial-loading-spinner" [style.display]="loading ? 'block' : 'none'"></mat-spinner>
    </div>
    <div [hidden]="loading" id="cell-latest-status-container">
        <h2 i18n>Latest Status</h2>
        <mat-card appearance="outlined" class="cell-latest-status-card">
            <div class="krucial-space-between-title-value">
                <div i18n><b>Last Transmission</b></div>
                <div data-cy="last-extended-status-packet-transmitted-at">{{gatewayLatestStatus?.lastTransmittedAt ?
                    (gatewayLatestStatus?.lastTransmittedAt | date:'HH:mm:ss - d/MM/yyyy') : '--'}}</div>
            </div>
            <mat-divider></mat-divider>
            <div class="krucial-space-between-title-value">
                <div i18n><b>Last Primary Sat Comms</b></div>
                <div data-cy="minutes-since-last-primary-sat-comms">{{gatewayLatestStatus?.minutesSinceLastPrimarySatComms ?
                    gatewayLatestStatus?.minutesSinceLastPrimarySatComms + ' ' + minutesAgoText : '--'}}</div>
            </div>
            <mat-divider></mat-divider>
            <div class="krucial-space-between-title-value">
                <div i18n><b>Last Secondary Sat Comms</b></div>
                <div data-cy="minutes-since-last-secondary-sat-comms">{{gatewayLatestStatus?.minutesSinceLastSecondarySatComms ?
                    gatewayLatestStatus?.minutesSinceLastSecondarySatComms + ' ' + minutesAgoText : '--'}}</div>
            </div>
            <mat-divider></mat-divider>
            <div class="krucial-space-between-title-value">
                <div i18n><b>Last Cellular Comms</b></div>
                <div data-cy="minutes-since-last-cellular-comms">{{gatewayLatestStatus?.minutesSinceLastCellularComms ?
                    gatewayLatestStatus?.minutesSinceLastCellularComms + ' ' + minutesAgoText : '--'}}</div>
            </div>
            <mat-divider></mat-divider>
            <div class="krucial-space-between-title-value">
                <div i18n><b>Battery Voltage</b></div>
                <div data-cy="battery-voltage">{{gatewayLatestStatus?.batteryVoltage ?
                    gatewayLatestStatus?.batteryVoltage + 'V' : '--'}}</div>
            </div>
            <mat-divider></mat-divider>
            <div class="krucial-space-between-title-value">
                <div i18n><b>Charge Status</b></div>
                <div data-cy="charge-status">{{chargeStatus ? chargeStatus : '--'}}</div>
            </div>
        </mat-card>
    </div>
</div>