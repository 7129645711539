import {inject, Injectable} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {fetchAuthSession} from "@aws-amplify/auth";
import {SessionService} from "../services/session.service";

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>{
        const sessionService = inject(SessionService);

        let res = <Promise<boolean>> sessionService.sessionExpired().then(hasExpired => {

            // Session has expired, so lets try to refresh
            if (hasExpired) {
                sessionService.refresh().then(x => {
                    sessionService.sessionExpired().then(hasStillExpired => {
                        // Still expired, logout and force user to login again
                        if (hasStillExpired) {
                            this.router.navigate(['/logout']);
                            return false;
                        } else {
                            return true;
                        }
                    })
                })
            }
            fetchAuthSession()
              .then(authSession => {

                  // No role restrictions assigned to route, so allow any authenticated user.
                  if (!route.data["roles"] || route.data["roles"].length === 0) { return true; }

                  // If roles are present, check our token for authorization
                  if (
                    (authSession.tokens.accessToken.payload['cognito:groups'] as string[]) &&
                    (authSession.tokens.accessToken.payload['cognito:groups'] as string[]).filter(value => route.data["roles"].includes(value)).length > 0
                  ) {
                      return true;
                  }
                  sessionService.signOut().finally(() => {
                      return false
                  });

                  return false;
              }).catch(err => {
                sessionService.signOut().finally(() => {
                    return false
                });
            });

        });
        return res;
    }
}
