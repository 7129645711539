import { Component } from '@angular/core';
import {DeploymentManagementService} from "../deployment-management.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DeploymentDto} from "@r3-iot/api-sigma";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { UpperCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@UntilDestroy()
@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatButtonModule, MatIconModule, UpperCasePipe]
})
export class SummaryComponent {

  loading: boolean = true;
  deployment: DeploymentDto;

  constructor(private deploymentManagementService: DeploymentManagementService, private router: Router,
              private route: ActivatedRoute) {
    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deploymentDto: DeploymentDto) => {
        this.deployment = deploymentDto;
      }
    })
  }

  goToDeployments(): void {
    this.router.navigate(['home/deployments']);
  }

  goToKCell(): void {
    this.router.navigate(['../k-cell'], { relativeTo: this.route });
  }

  goToDevices(): void {
    this.router.navigate(['../devices'], { relativeTo: this.route });
  }

  goToDeploymentPluginConfig(deploymentName: string): void {
    this.router.navigate([`../plugins`], { relativeTo: this.route });
  }
}

