<div>
    <ng-container *ngIf="!deviceProfiles">
        <h1 mat-dialog-title i18n>No Device Profiles Found!</h1>
        <div mat-dialog-content>
            <div class="center-row" i18n> First create a device profile</div>
            <br>
            <button class="center-button" mat-raised-button color="primary" (click)="goToDeviceProfiles()" i18n>Go to Device Profiles</button>
        </div>
    </ng-container>

    <ng-container *ngIf="deviceProfiles">
        <h1 mat-dialog-title i18n>New Device</h1>
        <div mat-dialog-content>
            <form [formGroup]="form">
                <div>
                    <mat-form-field>
                        <mat-label i18n>Device Name</mat-label>
                        <input data-cy="Device-Name" matInput type="text" formControlName="deviceName">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label i18n>DevEUI</mat-label>
                        <input data-cy="Dev-Eui" matInput type="text" formControlName="devEui"
                               [(ngModel)]="typedDevEui" r3libAddSpacesCapitalise>
                        <mat-hint align="end" i18n>16 characters required</mat-hint>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label i18n>Device Profile</mat-label>
                        <mat-select data-cy="Device-Profile-Select"  formControlName="deviceProfileSelect">
                            <mat-option *ngFor="let deviceProfile of deviceProfiles"
                                        [attr.data-cy]="'data-device-profile-dropdown-' + deviceProfile.name"
                                        [value]="deviceProfile">{{deviceProfile.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label i18n>Latitude</mat-label>
                        <input data-cy="lat" matInput type="text" formControlName="lat">
                        <mat-hint align="end" i18n>Latitude of device</mat-hint>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label i18n>Longitude</mat-label>
                        <input data-cy="lng" matInput type="text" formControlName="lng">
                        <mat-hint align="end" i18n>Longitude of device</mat-hint>
                    </mat-form-field>
                </div>
                <ng-container *ngIf="form.controls.deviceProfileSelect.value">
                    <!-- OTAA supports join -->
                    <ng-container *ngIf="form.controls.deviceProfileSelect.value.supportsJoin === true">
                        <div>
                            <mat-label i18n>Application Key</mat-label>
                            <mat-form-field>
                                <input data-cy="Application-Key" matInput type="text" formControlName="applicationKey"
                                        [(ngModel)]="typedAppKey" r3libAddSpacesCapitalise>
                                <mat-hint align="end" i18n>32 characters required</mat-hint>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!-- ABP does not support join -->
                    <ng-container *ngIf="form.controls.deviceProfileSelect.value.supportsJoin === false">
                        <div>
                            <mat-label i18n>Network Session Key</mat-label>
                            <mat-form-field>
                                <input data-cy="Network-Session-Key" matInput type="text" formControlName="networkSessionKey"
                                       [(ngModel)]="typedNwkSKey" r3libAddSpacesCapitalise>
                                <mat-hint align="end" i18n>32 characters required</mat-hint>
                            </mat-form-field>
                        </div>

                        <div>
                            <mat-label i18n>Application Session Key</mat-label>
                            <mat-form-field>
                                <input data-cy="Application-Session-Key" matInput type="text" formControlName="applicationSessionKey"
                                       [(ngModel)]="typedAppSKey" r3libAddSpacesCapitalise>
                                <mat-hint align="end" i18n>32 characters required</mat-hint>
                            </mat-form-field>
                        </div>
                    </ng-container>
                </ng-container>
                <br>
                <div class="row-space-between-center">
                    <mat-label i18n>Tag :</mat-label>
                    <div class="row-layout-align-end">
                        <button id="add-tags-button" mat-raised-button color="accent" (click)="openAddTagsDialog()"
                                class="button row-layout-align-center">
                            <mat-icon>add_circle_outline</mat-icon>
                            <span i18n> Add Tag</span>
                        </button>
                    </div>
                </div>
                <br>
                <div class="krucial-table-container" *ngIf="tagsAdded">
                    <mat-table #tagsTable [dataSource]="tagsDatasource" class="mat-elevation-z8">
                        <ng-container matColumnDef="Key">
                            <mat-header-cell *matHeaderCellDef i18n> Key</mat-header-cell>
                            <mat-cell *matCellDef="let tag">
                                {{tag.key}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Value">
                            <mat-header-cell *matHeaderCellDef i18n> Value</mat-header-cell>
                            <mat-cell *matCellDef="let tag">
                                {{tag.value}}
                            </mat-cell>
                        </ng-container>

                        <!-- Operations -->
                        <ng-container matColumnDef="Operations">
                            <mat-header-cell *matHeaderCellDef> Operations</mat-header-cell>
                            <mat-cell *matCellDef="let tag">
                                <ng-container *userHasRole="'connect_admin'">
                                    <button mat-button color="primary" (click)="openEditTagDialog($event, tag)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </ng-container>

                                <ng-container *userHasRole="'connect_admin'">
                                    <button mat-button color="primary" (click)="openDeleteTagDialog($event, tag)" [attr.data-cy]="'tag-delete-' + tag.id">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </ng-container>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedTagsColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedTagsColumns;"></mat-row>
                    </mat-table>
                </div>
            </form>
        </div>
        <div mat-dialog-actions>
            <button mat-button mat-dialog-close i18n>Cancel</button>
            <button mat-raised-button *ngIf="!saving" [disabled]="form.invalid" color="primary"
                    data-cy="save-button" (click)="save()" i18n>
                Save
            </button>
            <div class="center-row" *ngIf="saving">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
        </div>
    </ng-container>
</div>
