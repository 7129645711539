import {DeviceDetailsComponent} from "./device-details/device-details.component";
import {DeviceSendComponent} from "./device-send/device-send.component";
import {ModuleWithProviders, NgModule} from "@angular/core";
import {R3CommonModule, UserHasRoleDirective, UserIsRoleDirective} from "@r3-iot/common";
import {DeploymentDeviceComponent} from "./deployment-device.component";
import {DeploymentDeviceService} from "./deployment-device.service";
import {CommonModule, TitleCasePipe} from "@angular/common";

import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
    imports: [
    CommonModule,
    R3CommonModule,
    ReactiveFormsModule,
    DeviceSendComponent,
    DeviceDetailsComponent,
    DeploymentDeviceComponent
],
    providers: [DeploymentDeviceService, TitleCasePipe],
    exports: [
        DeploymentDeviceComponent
    ]
})
export class DeploymentDeviceModule {
    static forRoot(configuration: any): ModuleWithProviders<DeploymentDeviceModule> {
        return {
            ngModule: DeploymentDeviceModule,
            providers: [
                UserIsRoleDirective, {provide: 'RolesConfig', useValue: configuration.RolesConfig},
                UserHasRoleDirective, {provide: 'RolesConfig', useValue: configuration.RolesConfig},
                // Inject environment variable
                {
                    provide: 'Environment', // you can also use InjectionToken
                    useValue: configuration.environment
                }
            ]
        };
    }
}
