import {Component, Host, OnInit} from '@angular/core';
import {DeploymentManagementService} from "./deployment-management.service";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {finalize, take} from "rxjs";
import {DeploymentDto} from "@r3-iot/api-sigma";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-deployment-management',
    templateUrl: './deployment-management.component.html',
    styleUrls: ['./deployment-management.component.scss'],
    // Define DeploymentManagementService as a provider so that child components can access it. This ensures the service
    // is disposed of when the app leaves the scope of CellDetailsComponent
    providers: [DeploymentManagementService],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, RouterOutlet]
})
export class DeploymentManagementComponent implements OnInit {

  loading: boolean = true;
  deployment: DeploymentDto;

  constructor(@Host() public deploymentService: DeploymentManagementService, private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    // This is the top level component. On load, we grab the ID from the URL and load that Deployment
    // into the Service. Each child component subsequently refers to the service to find the current deployment-management.
    this.route.params.pipe(untilDestroyed(this)).subscribe({
      next: params => {
        this.deploymentService.getDeployment(params.name)
            .pipe(
                take(1),
                finalize(() => {
                  this.loading = false;
                })
            ).subscribe({
          next: (deployment: DeploymentDto) => {
            this.deployment = deployment;
          }
        });
      }
    })
  }
}
