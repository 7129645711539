import {mapToCanActivate, Routes} from "@angular/router";
import {AuthorizationGuard} from "../../../../../../shared/guards/auth.guard";

export const DEPLOYMENT_DEVICE_ROUTES: Routes = [
    {path: '', redirectTo: 'details', pathMatch: 'full'},
    {path: 'details', loadComponent: () => import('./device-details/device-details.component').then(mod => mod.DeviceDetailsComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    {path: 'data', loadComponent: () => import('./deployment-device-data/deployment-device-data.component').then(mod => mod.DeploymentDeviceDataComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    {path: 'send', loadComponent: () => import('./device-send/device-send.component').then(mod => mod.DeviceSendComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])}
];