import { Component } from '@angular/core';
import {DeploymentManagementService} from "../deployment-management.service";
import {DeploymentDto} from "@r3-iot/api-sigma";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from "@angular/router";
import { NavItem, R3CommonModule } from "@r3-iot/common";
import { TitleCasePipe, NgFor, UpperCasePipe } from "@angular/common";
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@UntilDestroy()
@Component({
    selector: 'app-k-cell',
    templateUrl: './k-cell.component.html',
    styleUrls: ['./k-cell.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatButtonModule, MatIconModule, MatTabsModule, NgFor, R3CommonModule, RouterLink, RouterOutlet, UpperCasePipe]
})
export class KCellComponent {

  menuStatus = $localize`Status`;
  menuHousekeeping = $localize`Housekeeping`;
  menuQueue = $localize`Queue`;
  menuConfiguration = $localize`Configuration`;
  menuOperations = $localize`Operations`;

  tabs: NavItem[] = [
    {
      displayName: this.menuStatus,
      iconName: '',
      route: `./status`,
      userHasRole: '',
      children: [],
    },
    {
      displayName: this.menuHousekeeping,
      iconName: '',
      route: `./housekeeping`,
      userHasRole: '',
      children: [],
    },
    {
      displayName: this.menuQueue,
      iconName: '',
      route: `./queue`,
      userHasRole: '',
      children: [],
    },
    {
      displayName: this.menuConfiguration,
      iconName: '',
      route: `./configuration`,
      userHasRole: '',
      children: [],
    },
    {
      displayName: this.menuOperations,
      iconName: '',
      route: `./operations`,
      userHasRole: '',
      children: [],
    }
  ]

  activeTab: NavItem;

  loading: boolean = true;
  deployment: DeploymentDto;

  constructor(private deploymentManagementService: DeploymentManagementService, private router: Router,
              private route: ActivatedRoute, private titleCasePipe: TitleCasePipe) {
    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deploymentDto: DeploymentDto) => {
        this.deployment = deploymentDto;
      }
    })

    const currentRoute: string = this.titleCasePipe.transform(this.router.url.split('/').pop()) as string;
    this.activeTab = this.tabs.find(x => x.displayName.toLowerCase() === currentRoute.toLowerCase()) as NavItem;

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const route: string = this.titleCasePipe.transform(this.router.url.split('/').pop()) as string;
        this.activeTab = this.tabs.find(x => x.displayName.toLowerCase() === route.toLowerCase()) as NavItem;
      }
    });
  }

  goToSummary(): void {
    this.router.navigate(['../summary'], { relativeTo: this.route });
  }
}
